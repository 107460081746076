/* Pour la barre verticale */
::-webkit-scrollbar {
    width: 9px !important; /* Largeur de la barre de défilement */
}

::-webkit-scrollbar-track {
    margin: 10px 0px;
    background: #f1f1f1 !important; /* Couleur de fond de la piste */
}

::-webkit-scrollbar-thumb {
    border-radius: 99px;
    background: #c8c8c8 !important; /* Couleur de la barre de défilement */
}

/* Quand on survole ou clique sur la barre de défilement */
::-webkit-scrollbar-thumb:hover {
    background: #bababa !important;
}
