/* Modal Add Driver */

.floating-label {
  position: absolute;
  top: 0;
  left: 0.5rem;
  transform: translateY(0rem);
  font-size: 1.6rem;
  color: #808080;
  transition: transform 0.2s, font-size 0.2s, color 0.2s;
}

.driver-form-field {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
}
.driver-form-input {
  padding: 0.5rem;
  width: 100%;
  border: none;
  border-bottom: 1px solid #008080;
  background-color: transparent;
  transition: border-color 0.2s;
  font-size: 1.4rem;
}

.driver-form-input:focus,
.driver-form-input:active,
.driver-form-input:hover {
  border-color: #008080;
  outline: none;
  box-shadow: none;
}
.driver-form-input:focus + .floating-label,
.driver-form-input:valid + .floating-label {
  transform: translateY(-2rem);
  font-size: 1.6rem;
  color: #008080;
  outline: none;
}
.error-message {
  font-size: 1.2rem;
  color: red;
  padding-top: 10px;
}














.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem;
  }
  
  .input-container {
    position: relative;
  }

.input {
    padding: 0;
    padding-right: 2rem;
    background-color: white;
    box-shadow: none !important;
    border: none !important;
    margin-top: 0%;
    width: 300px;
    height: 40px;
    border-bottom: 1px solid #008080 !important;
  }
  
  .input:focus,
  .input:hover,
  .input:active {
    box-shadow: none !important;
    border: none !important;
    border-bottom: 1px solid #008080 !important;
    outline: none;
  }
  .search-icon {
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  .add-driver-button {
    border: 1px solid #008080;
    color: #008080;
    font-size: 1.4rem;
    letter-spacing: 1.4px;
    font-weight: 600;
    /* background-color: #008080 !important;
    
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    
    border-radius: 5px;
    cursor: pointer; */
  }
  .add-driver-button:hover {
    color: #008080;
  }

  /* // Vous pouvez ajouter un peu de CSS pour faire tourner l'icône : */
.loading-icon {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
  

  .phone-form-input:focus,
  .phone-form-input:active,
  .phone-form-input:hover {
    outline: none !important;
    box-shadow: none !important;
  }
  

  
 
  
  .dropdown-icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    pointer-events: none;
  }
  .location-field {
    width: 100%;
    padding-right: 2rem;
  }

  .select-option{
    background-color: #dad6d6 !important;
    color: rgb(93, 89, 89);
    align-items: center;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  