.datepicker {
    border: none;
    border-bottom: 1px solid #008080;
    font-weight: bold;
    letter-spacing: 1.2px;
}
#selectedTypeContrat{
    outline: none;
    box-shadow: none;
    border-color: #008080;
}
#selectedTypeContrat:hover, #selectedTypeContrat:active, #selectedTypeContrat:focus{
    outline: none;
    box-shadow: none;
}