.scrollable-list {
    max-height: 300px; /* Hauteur fixe pour la liste */
    overflow-y: auto; /* Affiche une barre de défilement si nécessaire */
    scrollbar-width: thin; /* Pour Firefox */
    scrollbar-color: transparent; /* Pour Firefox, couleur du scrollbar et du thumb */
  }
  
  /* Stylisation de la scrollbar pour Chrome, Safari, etc. */
  .scrollable-list::-webkit-scrollbar {
    width: 5px; /* largeur du scrollbar */
  }
  
  .scrollable-list::-webkit-scrollbar-track {
    background: transparent; /* couleur de fond de la piste */
  }
  
  .scrollable-list::-webkit-scrollbar-thumb {
    background-color: rgba(128, 128, 128, 0.5); /* couleur du thumb */
    border-radius: 20px; /* arrondir les angles du thumb */
    border: 3px solid transparent; /* bordure autour du thumb */
  }
  