.navCustomer {
  min-height: 100vh;
  background: #ffffff;
  position: fixed;
}
.navigation {
  position: fixed;
  inset: 20px;
  background: #008080;
  width: 80px;
  border-left: 10px solid #008080;
  border-radius: 50px;
  overflow: hidden;
  box-shadow: 15px 15px 25px rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  display: flex; /* Ajoutez cette ligne */
  flex-direction: column; /* Ajoutez cette ligne */
  align-items: center;
}
.navigation.active {
  width: 300px;
  border-radius: 20px;
}
.toggle{
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.toggle::before{
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  border-radius: 3px;
  background: #008080;
  transform: translateY(-5px);
  transition: 1s;
}
.toggle::after{
  content: '';
  position: absolute;
  width: 26px;
  height: 3px;
  border-radius: 3px;
  background: #008080;
  transform: translateY(5px);
  transition: 1s;
}
.navigation.active .toggle::before{
  transform: translateY(0px) rotate(-405deg);
}
.navigation.active .toggle::after{
  transform: translateY(0px) rotate(225deg);
}
.navigation ul {
  position: absolute;
  top: 100px;
  left: 0;
  bottom: 70px;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding-top: 20px;
}
.navigation ul li .linkCustomer:not(.notAccess){
  position: relative;
  list-style: none;
  width: 100%;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
}

.navigation ul li:hover .linkCustomer:not(.notAccess){
  background: #ffffff;
  padding: 0 15px;
}


.navigation ul li .linkCustomer:not(.notAccess):hover::after {
  content: '';
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 7.5px -7.5px 0 7.5px #ffffff;
}

.navigation ul li .linkCustomer:not(.notAccess):hover::before {
  content: '';
  position: absolute;
  top: -20px;
  right: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border-bottom-right-radius: 20px;
  box-shadow: 7.5px 7.5px 0 7.5px #ffffff;
}
.navigation ul li .linkCustomer {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #ffffff;
}

.logo {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #ffffff;
}
.navigation ul li:hover .linkCustomer {
  color: #008080;
}
.navigation ul li .linkCustomer .icon{
  position: relative;
  display: block;
  min-width: 50px;
  height: 60px;
  line-height: 70px;
  text-align: start;

}
.logo .icon{
  position: relative;
  display: block;
  min-width: 50px;
  height: 60px;
  line-height: 70px;
  text-align: start;

}
.navigation ul li .linkCustomer .icon ion-icon{
  font-size: 2rem;
}
.navigation ul li .linkCustomer .title{
  font-size: 1.75rem;
  position: relative;
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
}
.logo .title{
  font-size: 1.75rem;
  position: relative;
  display: block;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
  padding: 10px 0 0 20px;
  letter-spacing: 15px;
}
/* .navCustomer::-webkit-scrollbar {
  width: 0px;
}
.navCustomer::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 10px;
} */
.navScroll::-webkit-scrollbar {
  display: none; /* Cela rend la barre de défilement non visible et non utilisable */
}
.navigation ul li .activeItem {
  color: #008080;
  background: #ffffff;
  border-bottom-left-radius: 30px;
  border-top-left-radius: 30px;
  padding: 0 15px;
}
.navigation ul li:hover .activeItem {
  color: #008080;
  padding: 0;
}
.navigation ul li .activeItem:hover {
  padding: 0;
}
.navigation ul li .activeItem::after {
  content: '';
  position: absolute;
  bottom: -20px;
  right: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 7.5px -7.5px 0 7.5px #ffffff;
}
.navigation ul li .activeItem::before {
  content: '';
  position: absolute;
  top: -20px;
  right: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border-bottom-right-radius: 20px;
  box-shadow: 7.5px 7.5px 0 7.5px #ffffff;
}
.navigation ul li:hover .activeItem::after {
  content: '';
  position: absolute;
  bottom: -20px;
  right: -20px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-top-right-radius: 20px;
  box-shadow: 7.5px -7.5px 0 7.5px #ffffff;
  padding: 0;
}
.navigation ul li:hover .activeItem::before {
  content: '';
  position: absolute;
  top: -20px;
  right: -20px;
  width: 20px;
  height: 20px;
  background: transparent;
  border-bottom-right-radius: 20px;
  box-shadow: 7.5px 7.5px 0 7.5px #ffffff;
  padding: 0;
}
/* ... Vos autres styles ... */

.navigation ul li .linkCustomer.notAccess {
  cursor: not-allowed !important;
  color: rgba(255, 255, 255, 0.5);
  z-index: 200 !important;
}

.navigation ul li .linkCustomer.notAccess span {
  pointer-events: none;
}

.navigation ul li .linkCustomer.notAccess:hover::before {
  content: 'Accès non autorisé';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: rgba(255, 0, 0, 0.7);
  color: #ffffff;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
}
.navigation ul li .linkCustomer.notAccess:hover {
  color: rgba(255, 255, 255, 0.5);
}
