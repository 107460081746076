.add-driver-button {
    border: 1px solid #008080;
    color: #008080;
    font-size: 1.4rem;
    letter-spacing: 1.4px;
    font-weight: 600;
    /* background-color: #008080 !important;
    
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    
    border-radius: 5px;
    cursor: pointer; */
  }
  .add-driver-button:hover {
    background: #008080;
    color: #fff;
  }