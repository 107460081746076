.table-recap {
    width: 95%; /* Assurez-vous que le tableau prend toute la largeur disponible */
    border-bottom: 2px solid #008080;
}
.table-recap thead {
   border-bottom: 2px solid #e0e0e0;
   font-size: 1.4rem;
}

.table-recap td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    vertical-align: middle;
    font-size: 1.4rem;
    border-bottom: 1px solid #e0e0e0;
}

.th-flex {
    display: flex;
    justify-content: center; /* Centrer horizontalement */
    align-items: center;     /* Centrer verticalement */
    text-align: center;      /* Assurez-vous que le texte à l'intérieur est également centré */
    height: 100%;            /* Optionnel : ajustez selon la hauteur désirée */
}