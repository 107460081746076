.button-navbar {
    background: transparent !important;
    border: none !important;
    color: black !important;
    font-weight: 600 !important;
    border-radius: 0px !important;
}

.button-navbar-active {
    background: transparent !important;
    border: none !important;
    border-bottom: 2px solid #008080 !important;
    border-radius: 0px !important;
    color: black !important;
    font-weight: 600 !important;
}

.button-navbar:hover, .button-navbar:active, .button-navbar:focus, .button-navbar-active:hover, .button-navbar-active:active, .button-navbar-active:focus {
    color: #008080 !important;
    outline: none !important;
    box-shadow: none !important;

}