.table-personnalisee {
    width: 95%; /* Assurez-vous que le tableau prend toute la largeur disponible */
    margin-top: 5rem;

}
.table-personnalisee thead {
   border-bottom: 2px solid #e0e0e0;
   font-size: 1.6rem;

}
.table-personnalisee td {
    padding-top: 1rem;
    padding-bottom: 1rem;
    vertical-align: middle;
    font-size: 1.5rem;
}


/* 

.table-personnalisee, .table-personnalisee th, .table-personnalisee td {
    padding: 0.5rem; *//* Ajustez cela selon vos besoins *//*
    border: 1px solid #dee2e6; *//* La bordure par défaut de Bootstrap *//*
    width: auto !important; 
    max-width: none !important;
    min-width: 0 !important;
}


.table-personnalisee th {
    background-color: #f2f2f2;
    color: black;
}

.table-personnalisee tr:hover {
    background-color: #f5f5f5;
}

.table-personnalisee tbody tr:nth-child(even) {
    background-color: #f9f9f9;
} */
