* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
html {
  font-size: 62.5%; /* 62.5% de 16px est 10px */
}
ul, ol {
  list-style: none;
}
body {
  font-family: 'Montserrat','Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  background: #ffffff;
}

.btn{
	box-shadow: none !important
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn:focus {
  outline: none;
  box-shadow: none;
  }

input{
	box-shadow: none !important
}

input:focus {
  outline: none;
  box-shadow: none;
}
textarea{
	outline: none;
	box-shadow: none !important;
}
textarea:focus {
	outline: none;
	box-shadow: none;
  }
  @keyframes clignotant {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.background-rouge-clignotant {
    animation: clignotant 2s linear infinite;
    background-color: rgba(255, 0, 0, 0.5); /* Rouge avec opacité */
    color: #ffffff;
}

.background-vert-clignotant {
    background-color: rgba(13, 160, 13, 0.7); /* Vert avec opacité */
    color: #ffffff;
}










/* .btn{
	box-shadow: none !important
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.btn:focus {
  outline: none;
  box-shadow: none;
  }

input{
	box-shadow: none !important
}

input:focus {
  outline: none;
  box-shadow: none;
}
textarea{
	outline: none;
	box-shadow: none !important;
}
textarea:focus {
	outline: none;
	box-shadow: none;
  }

  .custom-dropdown .btn{
    background: #f1f1f1 !important;
    border: none !important;
    color: #008080 !important;
}
.custom-dropdown1 .btn{
  background: #f1f1f1 !important;
  border: none !important;
  color: #333 !important;
  max-width: 260px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
}
.custom-dropdown .dropdown-item {
    width: 100% !important;
    max-width: 300px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 8px 20px;
    margin: 0;
    color: #333;
    font-size: 14px;
  }
  .custom-dropdown1 .dropdown-item {
    width: 100% !important;
    max-width: 300px !important;
    padding: 8px 20px;
    margin: 0;
    color: #333;
    font-size: 14px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  }
  .dropdown-menu {
    max-height: 250px; 
    overflow-y: auto; 
  }

.custom-dropdown .dropdown-item:hover, .custom-dropdown1 .dropdown-item:hover {
    color: #008080 !important;
}
.custom-dropdown .dropdown-item:active, .custom-dropdown1 .dropdown-item:active {
    background: #f1f1f1 !important;
} */


  
 